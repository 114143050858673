import { DefaultLightTheme } from './default-light'

// load theme images that rely on next static images
const _staticThemeImages = {
  canaveral: require('publicImages/themes/canaveral.png'),
  atmosphere: require('publicImages/themes/atmosphere-background.png'),
  gravity: require('publicImages/themes/gravity-background.png'),
  prism: require('publicImages/themes/prism-background.png'),
  satellite: require('publicImages/themes/satellite-background.jpg'),
}

export const DEFAULT_THEME = DefaultLightTheme
