export const animals = [
  'aardvark',
  'aardwolf',
  'albatross',
  'alligator',
  'alpaca',
  'anaconda',
  'angelfish',
  'ant',
  'anteater',
  'antelope',
  'armadillo',

  'badger',
  'bandicoot',
  'barnacle',
  'barracuda',
  'bass',
  'bat',
  'bear',
  'beaver',
  'bird',
  'bison',
  'blackbird',
  'boar',
  'bobcat',
  'butterfly',

  'camel',
  'cardinal',
  'caribou',
  'cat',
  'caterpillar',
  'cattle',
  'centipede',
  'chameleon',
  'cheetah',
  'chimpanzee',
  'chinchilla',
  'chipmunk',
  'cicada',
  'clam',
  'clownfish',
  'cobra',
  'condor',
  'cougar',
  'cow',
  'coyote',
  'crab',
  'crane',
  'crayfish',
  'cricket',
  'crocodile',

  'deer',
  'dinosaur',
  'dog',
  'dolphin',
  'dove',
  'dragon',
  'dragonfly',
  'duck',

  'eagle',
  'eel',
  'egret',
  'elephant',
  'elk',
  'emu',

  'falcon',
  'ferret',
  'finch',
  'firefly',
  'fish',
  'flamingo',
  'fox',
  'frog',

  'gazelle',
  'gecko',
  'giraffe',
  'goat',
  'goldfish',
  'goose',
  'gopher',
  'gorilla',
  'grasshopper',

  'halibut',
  'hamster',
  'hare',
  'hawk',
  'hedgehog',
  'hippopotamus',
  'horse',
  'hummingbird',

  'iguana',

  'jackal',
  'jaguar',
  'jellyfish',

  'kangaroo',
  'kiwi',
  'koala',

  'ladybug',
  'lemming',
  'lemur',
  'leopard',
  'lion',
  'lizard',
  'llama',
  'lobster',
  'lynx',

  'macaw',
  'mammal',
  'manatee',
  'marlin',
  'marmot',
  'marsupial',
  'mastodon',
  'meerkat',
  'mockingbird',
  'mongoose',
  'monkey',
  'moose',
  'mouse',
  'mule',

  'narwhal',
  'nightingale',

  'ocelot',
  'octopus',
  'orangutan',
  'orca',
  'ostrich',
  'otter',
  'owl',

  'panda',
  'panther',
  'parakeet',
  'parrot',
  'pelican',
  'penguin',
  'platypus',
  'pony',
  'porcupine',
  'porpoise',
  'puffin',
  'puma',
  'python',

  'rabbit',
  'raccoon',
  'rattlesnake',
  'raven',
  'reindeer',
  'rhinoceros',
  'roadrunner',
  'rooster',

  'salamander',
  'salmon',
  'scallop',
  'seahorse',
  'shark',
  'sheep',
  'sloth',
  'snake',
  'sparrow',
  'squid',
  'squirrel',
  'starfish',
  'stingray',
  'stork',
  'swan',
  'swordfish',

  'tiger',
  'toad',
  'tortoise',
  'toucan',
  'trout',
  'tyrannosaurus',

  'unicorn',

  'viper',

  'wallaby',
  'walrus',
  'whale',
  'wildcat',
  'wildebeest',
  'wolf',
  'wolverine',

  'zebra',
]
