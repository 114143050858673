import { useEffect } from 'react'
import * as workerTimers from 'worker-timers'

const WAKEUP_INTERVAL_PERIOD = 1000
const WAKEUP_SKEW_FACTOR = 30

// Use a 30s skew time. Note that during heavy computations the skew
// of an active browser actually gets into the 5-10s range
const MINIMUM_SKEW_TIME = WAKEUP_INTERVAL_PERIOD * WAKEUP_SKEW_FACTOR

/**
 * Hook to call a Callback whenever we "wake up".
 *
 * Detect waking up from sleeping by computing the difference between
 * where we expect the clock to be and where it actually is (skew), leaving
 * some wiggle room of a couple seconds to account for imperfect javascript timing
 */
export const useWakeUpDetector = (callback: () => void) => {
  // Setup intervals to refresh the session cookie and detect "waking up"
  useEffect(() => {
    let lastKnownTime = new Date().getTime()

    const wakeupDetectionInterval = workerTimers.setInterval(() => {
      const currentTime = new Date().getTime()
      if (
        currentTime >
        lastKnownTime + WAKEUP_INTERVAL_PERIOD + MINIMUM_SKEW_TIME
      ) {
        console.log(
          '[useWakeUpDetector] Probably just woke up! Calling callback ' +
            callback.name
        )
        callback()
      }
      lastKnownTime = currentTime
    }, WAKEUP_INTERVAL_PERIOD)

    return () => {
      workerTimers.clearInterval(wakeupDetectionInterval)
    }
  }, [callback])
}
