// Based on https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
/* ISC License (ISC). Copyright 2017 Michal Zalecki */
import { isStorageSupported } from './utils'
export const storageFactory = (getStorage: () => Storage): Storage => {
  let inMemoryStorage: { [key: string]: string } = {}

  const clear = (): void => {
    if (isStorageSupported) {
      getStorage().clear()
    } else {
      inMemoryStorage = {}
    }
  }

  const getItem = (name: string): string | null => {
    if (isStorageSupported) {
      return getStorage().getItem(name)
    }
    if (Object.prototype.hasOwnProperty.call(inMemoryStorage, name)) {
      return inMemoryStorage[name]
    }
    return null
  }

  const key = (index: number): string | null => {
    if (isStorageSupported) {
      return getStorage().key(index)
    } else {
      return Object.keys(inMemoryStorage)[index] || null
    }
  }

  const removeItem = (name: string): void => {
    if (isStorageSupported) {
      getStorage().removeItem(name)
    } else {
      delete inMemoryStorage[name]
    }
  }

  const setItem = (name: string, value: string): void => {
    if (isStorageSupported) {
      getStorage().setItem(name, value)
    } else {
      inMemoryStorage[name] = String(value) // not everyone uses TypeScript
    }
  }

  const length = (): number => {
    if (isStorageSupported) {
      return getStorage().length
    } else {
      return Object.keys(inMemoryStorage).length
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length()
    },
  }
}
