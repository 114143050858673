import { Editor } from '@tiptap/core'
import React, { useCallback } from 'react'

import { isUndoShortcut, preventDefaultUndo } from 'modules/keyboard'
export const useForwardUndo = (editor: Editor | null | undefined) => {
  return useCallback(
    (ev: KeyboardEvent | React.KeyboardEvent) => {
      if (!editor) return false
      // Listens for Ctrl/Cmd-Z (undo) or Shift-Ctrl/Cmd-Z (redo) outside the editor
      // and forwards the commands to the editor to undo changes within them
      // Returns true if it triggers to stop the event bubbling
      if (isUndoShortcut(ev)) {
        // Prevent the native undo, see usePreventDefaultUndo comment above
        preventDefaultUndo(ev)
        if (ev.shiftKey) {
          return editor.commands.redo()
        } else {
          return editor.commands.undo()
        }
      }
      return false
    },
    [editor]
  )
}
