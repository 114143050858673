import { MouseEventHandler } from 'react'

// Prevent the focus event from firing. Without this, clicking as button would trigger a focus on this element, revealing the tooltip when a user changes browser tabs then comes back (annoying!). See https://stackoverflow.com/a/45131490
export const preventDefaultToAvoidBlur: MouseEventHandler = (ev) =>
  ev.preventDefault()

// This allows drag/drop to work for certain elements inside Prosemirror NodeViews that
// would otherwise prevent the drag end event from working
export const preventDefaultToAllowDrop = (
  ev: React.DragEvent<HTMLDivElement>
) => ev.preventDefault()

export const stopPropagation: MouseEventHandler = (ev) => ev.stopPropagation()

export const preventDefaultAndStopPropagation: MouseEventHandler = (ev) => {
  ev.preventDefault()
  ev.stopPropagation()
}
