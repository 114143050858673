import throttle from 'lodash/debounce'
import { useEffect, useState } from 'react'

// The extra time we'll wait beyond the period for an event to come in.
const TIMEOUT_BUFFER = 100

export const useWindowResizing = (period = 250) => {
  const [isResizing, setResizing] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const handleResize = throttle(
      () => {
        clearTimeout(timeout)
        setResizing(true)

        // Wait for a little longer than the period to ensure the
        // throttled events have stopped coming in before we set
        // the resizing state back to false.
        timeout = setTimeout(() => {
          setResizing(false)
        }, period + TIMEOUT_BUFFER)
      },
      period,
      { leading: true, trailing: true, maxWait: period }
    )

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [period])

  return isResizing
}
