export { isCardNode } from '../extensions/Card/utils'

import { Editor } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { Decoration } from 'prosemirror-view'

export const isBlockNode = (node: Node) => {
  const groups = node.type.spec.group?.split(' ') || []
  return groups.includes('block') || groups.includes('cardBlock')
}

export const getDecorationsForNode = (editor: Editor, pos: number) => {
  let decorations: Decoration[] = []
  try {
    // this is a bit of a hack since docView doesn't expose the `descAt` method in the TS type, but it exists
    decorations = (editor as any).view.docView.descAt(pos)?.outerDeco || [] // outerDeco are the decorations AT that node
  } catch (e) {
    console.error(
      `[getDecorationsForNode] unable to find decorations at pos ${pos}`,
      e.message
    )
  }
  return decorations
}
