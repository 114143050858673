import { analyzeBreakpoints } from '@chakra-ui/utils'
import { gammaTheme } from '@gamma-app/ui'
import debounce from 'lodash/debounce'
import memoize from 'lodash/memoize'

import { EventEmitter } from './EventEmitter'

type GammaBreakpointValue = string

class WindowBreakpointEmitter extends EventEmitter<{
  breakpoint: {
    val: GammaBreakpointValue
  }
}> {
  private breakpoints: { breakpoint: GammaBreakpointValue; query: string }[]
  private values: {
    [breakpoint: GammaBreakpointValue]: boolean
  } = {}
  private emitBreakpoint: () => void

  public unbind: () => void

  constructor() {
    super()
    if (typeof window === 'undefined') {
      // dont do anything on server
      return
    }
    const analyzed = analyzeBreakpoints(gammaTheme.breakpoints)
    if (!analyzed) {
      return
    }

    this.breakpoints = analyzed.details.map(({ minMaxQuery, breakpoint }) => ({
      breakpoint,
      query: minMaxQuery.replace('@media screen and ', ''),
    }))

    // init values
    this.breakpoints.forEach(({ breakpoint }) => {
      this.values[breakpoint] = false
    })

    // initial compute
    this.compute()

    // setup listeners
    const mql = this.breakpoints.map(({ query }) => window.matchMedia(query))

    const handler = (evt: MediaQueryListEvent) => {
      const found = this.breakpoints.find((a) => a.query === evt.media)
      if (found) {
        this.values[found.breakpoint] = evt.matches
        this.emitBreakpoint()
      }
    }

    // trail debounce because the way the MediaQueryList handler fires is it will send two events
    // 1. to unset the current media match where evt.matches === false
    // 2. to set the current media match where evt.matches === true
    this.emitBreakpoint = debounce(
      () => {
        const val = this.getBreakpointValue()
        if (!val) {
          return
        }
        this.emit('breakpoint', {
          val,
        })
      },
      50,
      {
        trailing: true,
      }
    )

    mql.forEach((mql2) => {
      if (typeof mql2.addListener === 'function') {
        mql2.addListener(handler)
      } else {
        mql2.addEventListener('change', handler)
      }
    })

    this.unbind = () => {
      mql.forEach((mql2) => {
        if (typeof mql2.removeListener === 'function') {
          mql2.removeListener(handler)
        } else {
          mql2.removeEventListener('change', handler)
        }
      })
    }
  }

  getBreakpointValue(fallback?: string): GammaBreakpointValue {
    for (const [key, val] of Object.entries(this.values)) {
      if (val) {
        return key
      }
    }
    if (fallback) {
      return fallback
    }
    return ''
  }

  compute() {
    for (const { breakpoint, query } of this.breakpoints) {
      this.values[breakpoint] = window.matchMedia(query).matches
    }
  }
}

export const getBreakpointEmitter = memoize(() => new WindowBreakpointEmitter())
