import { useEffect, DependencyList, EffectCallback, useRef } from 'react'

// Inspired from https://stackoverflow.com/a/58125085/1171978
// Provides a useEffect hook that only runs when specific dependencies change
// that are different from the dependencies of the hook itself.
export const useEffectWhen = (
  effect: EffectCallback,
  deps: DependencyList,
  whenDeps: DependencyList,
  runInitial: boolean = true
) => {
  const whenRef = useRef(whenDeps || [])
  const initial = whenRef.current === whenDeps
  const whenDepsChanged =
    (initial && runInitial) ||
    !whenRef.current.every((w, i) => w === whenDeps[i])
  whenRef.current = whenDeps
  const nullDeps = deps.map(() => null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(
    whenDepsChanged ? effect : () => {},
    whenDepsChanged ? deps : nullDeps
  )
}
