import { useToast } from '@chakra-ui/react'
import { debounce, throttle } from 'lodash'
import { useEffect, useState } from 'react'

import { DebouncedFunc, DebounceSettings, ThrottleSettings } from 'types/lodash'

export function useDebounced<T extends (...args: any[]) => any | undefined>(
  callback: T,
  delay: number,
  opts?: DebounceSettings
) {
  const [fn, setFn] = useState<DebouncedFunc<T>>(() =>
    debounce(callback, delay, opts)
  )

  useEffect(() => {
    setFn(() => debounce(callback, delay, opts))
  }, [callback, delay, opts])

  return fn
}

export function useThrottled<T extends (...args: any[]) => any | undefined>(
  callback: T,
  delay: number,
  opts?: ThrottleSettings
) {
  const [fn, setFn] = useState<DebouncedFunc<T>>(() =>
    throttle(callback, delay, opts)
  )

  useEffect(() => {
    setFn(() => throttle(callback, delay, opts))
  }, [callback, delay, opts])

  return fn
}

export function useHandleError(title: string, error?: { message: string }) {
  const toast = useToast()

  useEffect(() => {
    if (!error) return
    toast({
      title,
      description: `${error.message}.`,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }, [title, toast, error])
}
