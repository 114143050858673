import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

export type SearchModalState = {
  isOpen: boolean
  initialQuery: string
}

const initialState: SearchModalState = {
  isOpen: false,
  initialQuery: '',
}

const SearchModalSlice = createSlice({
  name: 'SearchModal',
  initialState,
  reducers: {
    reset: () => initialState,
    setIsGlobalSearchOpen(
      state: SearchModalState,
      action: PayloadAction<{
        isOpen: boolean
        initialQuery?: string
      }>
    ) {
      const { isOpen, initialQuery } = action.payload
      state.isOpen = isOpen
      state.initialQuery = initialQuery || ''
    },
  },
})

export const { setIsGlobalSearchOpen } = SearchModalSlice.actions

type SliceState = Pick<RootState, 'SearchModal'>

// Selectors
export const selectIsGlobalSearchOpen = (state: SliceState): boolean => {
  return state.SearchModal.isOpen
}
export const selectInitialQuery = (state: SliceState): string => {
  return state.SearchModal.initialQuery
}

// Reducer
export const reducer = SearchModalSlice.reducer
