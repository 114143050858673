import { EventEmitter } from 'utils/EventEmitter'

type NavigationAction = {
  url: string
  method: 'push' | 'replace' | 'pop' | null
  hashChanged: boolean
}

type ScrollPositionState = {
  // Gamma implementation of tracking scroll-position
  // See https://html.spec.whatwg.org/multipage/browsing-the-web.html#persisted-user-state-restoration:she-scroll-position
  fromPos?: number | null
  fromPct?: number | null
}

export type GammaHistoryState = NextHistoryState & ScrollPositionState

type URLEvents = {
  changed: URLChangedPayload
}

export type URLChangedPayload = NavigationAction & {
  state: GammaHistoryState
}

export const URLEventEmitter = new EventEmitter<URLEvents>()

const lastNavigationAction: NavigationAction = {
  url: typeof window === 'undefined' ? '' : window.location.href,
  method: null,
  hashChanged: false,
}

export const setupPopstateListener = () => {
  window.addEventListener(
    'popstate',
    () => {
      const previousUrl = new URL(lastNavigationAction.url)
      const hashChanged = previousUrl.hash !== window.location.hash

      lastNavigationAction.method = 'pop'
      lastNavigationAction.url = window.location.href
      lastNavigationAction.hashChanged = hashChanged

      // Dispatch a Gamma Specific URL change event
      URLEventEmitter.emit('changed', {
        ...lastNavigationAction,
        state: window.history.state,
      })
    },
    false
  )
}

type QueryParams = {
  [key: string]: string | string[] | undefined
}
type HistoryArgs = {
  pathname?: string
  query?: QueryParams
  hash?: string
  method: 'push' | 'replace'
  emitChange?: boolean
  data?: Record<string, any>
}

export const pushState = (args: Omit<HistoryArgs, 'method'>) => {
  historyState({
    ...args,
    method: 'push',
  })
}

export const replaceState = (args: Omit<HistoryArgs, 'method'>) => {
  historyState({
    ...args,
    method: 'replace',
  })
}
// Method to update the history state without causing unnecessary application re-renders.
// Background: Unfortunately, using the Next.js router's `replace` and `push` methods
// causes the application to re-render.
const historyState = ({
  pathname,
  query,
  hash,
  method,
  emitChange = true,
  data = {},
}: HistoryArgs) => {
  if (typeof window === 'undefined') return
  const url = new URL(window.location.href)

  if (pathname !== undefined) {
    url.pathname = pathname
  }
  if (hash !== undefined) {
    url.hash = hash
  }
  if (query !== undefined) {
    // Turning url.searchParams into a new array avoids inadvertently mutating
    // the iterator as we delete keys from url.searchParams
    for (const [key] of [...url.searchParams.entries()]) {
      url.searchParams.delete(key)
    }
    for (const [key, value] of Object.entries(query)) {
      if (value && typeof value === 'string') {
        url.searchParams.set(key, value)
      }
    }
    url.searchParams.sort()
  }
  // I arrived at this shape based on the discussion here, https://github.com/vercel/next.js/discussions/18072,
  // as well as manual experimentation with the `as` parameter
  const state = {
    ...window.history.state,
    ...data,
    as: `${url.pathname}`,
    url: url.toString(),
  }
  const hashChanged = url.hash !== window.location.hash
  // NextJS router relies on a state object of type `HistoryState`. The goal
  // here is to retain the state, but replace the URL.
  // https://github.com/vercel/next.js/blob/65e704daa47c298449b84d9e8720301b3a6dc719/packages/next/shared/lib/router/router.ts#L96-L99
  if (method === 'push') {
    window.history.pushState(state, '', url.toString())
  } else {
    window.history.replaceState(state, '', url.toString())
  }

  lastNavigationAction.url = url.toString()
  lastNavigationAction.method = method
  lastNavigationAction.hashChanged = hashChanged

  if (emitChange) {
    // Dispatch a Gamma Specific URL change event
    URLEventEmitter.emit('changed', {
      ...lastNavigationAction,
      state: window.history.state,
    })
  }
}
