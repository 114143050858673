import Libhoney from 'libhoney'

import { config } from 'config'

import {
  MeasureDownloadDocEvent,
  MeasureEditorRenderEvent,
  MeasureKeydownEvent,
  MeasureModeChangeEvent,
  MeasureSlideToSlideEvent,
} from './types'

type FieldValue = string | number | undefined | null | boolean
type Fields = Record<string, FieldValue>

const libhoney = new Libhoney({
  // apiHost: "https://api.honeycomb.io/",

  // http
  // proxy: undefined,

  // sample rate of data.  causes us to send 1/sample-rate of events
  // i.e. `sampleRate: 10` means we only send 1/10th the events.
  sampleRate: 10,

  // transmission constructor, or a string to pick one of our builtin versions.
  // we fall back to the base impl if worker or a custom implementation throws on init.
  // string options available are:
  //  - "base": the default transmission implementation
  //  - "worker": a web-worker based transmission (not currently available, see https://github.com/honeycombio/libhoney-js/issues/22)
  //  - "mock": an implementation that accumulates all events sent
  //  - "writer": an implementation that logs to the console all events sent (deprecated.  use "console" instead)
  //  - "console": an implementation that logs correct json objects to the console for all events sent.
  //  - "stdout": an implementation that logs correct json objects to standard out, useful for environments where console.log is not ideal (e.g. AWS Lambda)
  //  - "null": an implementation that does nothing
  transmission: 'base',

  // batch triggers
  batchSizeTrigger: 50, // we send a batch to the api when we have this many outstanding events
  batchTimeTrigger: 2000, // ... or after this many ms has passed.

  // batches are sent serially (one event at a time), so we allow multiple concurrent batches
  // to increase parallelism while sending.
  maxConcurrentBatches: 3,

  // the maximum number of pending events we allow in our to-be-batched-and-transmitted queue before dropping them.
  pendingWorkCapacity: 10000,

  // the maximum number of responses we enqueue before we begin dropping them.
  maxResponseQueueSize: 1000,

  // how long (in ms) to give a single POST before we timeout.
  timeout: 30000,

  // if this is set to true, all sending is disabled.  useful for disabling libhoney when testing
  disabled: !config.HONEYCOMB_DATASET || !config.HONEYCOMB_API_KEY,

  // If this is non-empty, append it to the end of the User-Agent header.
  // userAgentAddition: ""

  writeKey: config.HONEYCOMB_API_KEY,

  dataset: config.HONEYCOMB_DATASET,
})

if (process.browser) {
  libhoney.add({
    userAgent: window.navigator.userAgent,
    version: config.GIT_SHA,
  })
}

const sendHoneycombEvent = ({ data }: { data: Fields }) => {
  if (!window.navigator.onLine) return

  const ev = libhoney.newEvent()
  ev.add(data)
  ev.send()
}

export const sendDownloadDocEvent = (
  data: Omit<MeasureDownloadDocEvent, 'type' | 'event'>
) => {
  return sendHoneycombEvent({
    data: {
      ...data,
      type: 'performance',
      event: 'download-doc',
    },
  })
}

export const sendEditorRenderEvent = (
  data: Omit<MeasureEditorRenderEvent, 'type' | 'event'>
) => {
  return sendHoneycombEvent({
    data: {
      ...data,
      type: 'performance',
      event: 'editor-render',
    },
  })
}

export const sendKeydownPerformanceEvent = (
  data: Omit<MeasureKeydownEvent, 'type' | 'event'>
) => {
  return sendHoneycombEvent({
    data: {
      ...data,
      type: 'performance',
      event: 'keydown',
    },
  })
}

export const sendModeChangePerformanceEvent = (
  data: Omit<MeasureModeChangeEvent, 'type' | 'event'>
) => {
  return sendHoneycombEvent({
    data: {
      ...data,
      type: 'performance',
      event: 'mode-change',
    },
  })
}

export const sendSlideToSlidePerformanceEvent = (
  data: Omit<MeasureSlideToSlideEvent, 'type' | 'event'>
) => {
  return sendHoneycombEvent({
    data: {
      ...data,
      type: 'performance',
      event: 'slide-to-slide',
    },
  })
}

// sample rate of data.  causes us to send 1/sample-rate of events
// i.e. `sampleRate: 10` means we only send 1/10th the events.
export const setHoneycombSampleRate = (rate: number) => {
  libhoney.sampleRate = rate
}

export const setHoneycombBaseData = (data: Fields) => {
  libhoney.add(data)
}
