import { createContext, useContext } from 'react'

import { EventEmitter } from 'utils/EventEmitter'

type PanelLifecycleEvents = {
  resize: {}
}

export class PanelLifecycle extends EventEmitter<PanelLifecycleEvents> {}

// The context value here can be undefined because the PanelLifecycleContext may sometimes not be provided
export const PanelLifecycleContext = createContext<PanelLifecycle | undefined>(
  undefined
)

export const usePanelLifecycle = () => {
  return useContext(PanelLifecycleContext)
}
