import { useEffect, useState } from 'react'

// Whether or not the app has hydrated
// This is set to true globally once the initial
// client side render (hydration) has completed
let hasHydrated = false

export const useSSRInitialize = () => {
  useEffect(() => {
    hasHydrated = true
  }, [])
}

/**
 * Simple hook to ensure SSR and CSR is the same
 * See https://nextjs.org/docs/messages/react-hydration-error
 */
export const useSSRMounted = () => {
  const [ready, setReady] = useState(hasHydrated)
  useEffect(() => setReady(true), [])

  return ready
}
