import {
  DocumentNode,
  OperationVariables,
  QueryOptions,
  useApolloClient,
} from '@apollo/client'
import { useCallback } from 'react'

// Helper hook to provide a lazy query function that returns a promise
// This is fixed in apollo client v4.5 but we cant upgrade until this
// issue is fixed: https://github.com/apollographql/apollo-client/issues/9204
export function useLazyQueryPromise<
  TData = any,
  TVariables = OperationVariables
>(query: DocumentNode, options?: OperationVariables) {
  const client = useApolloClient()
  const { fetchPolicy, ...rest } = options || {}
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query,
        variables,
        fetchPolicy: fetchPolicy || 'network-only',
        returnPartialData: false,
        ...rest,
      }),
    [client, fetchPolicy, query, rest]
  )
}
