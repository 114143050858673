export const adjectives = [
  'abstract',
  'abundant',
  'academic',
  'adjacent',
  'adorable',
  'advanced',
  'amazing',
  'anonymous',

  'balanced',
  'beautiful',
  'bold',
  'brave',
  'bright',
  'brilliant',

  'capable',
  'characteristic',
  'cheerful',
  'classical',
  'coastal',
  'colorful',
  'colossal',
  'confident',
  'cool',
  'corresponding',
  'courageous',
  'creative',

  'daily',
  'decisive',
  'delicious',
  'determined',
  'distinguished',
  'diverse',
  'divine',
  'dizzy',
  'dynamic',

  'educational',
  'elated',
  'electronic',
  'elegant',
  'enchanting',
  'energetic',
  'enthusiastic',
  'evolutionary',
  'exceptional',
  'exuberant',

  'famous',
  'fancy',
  'fantastic',
  'fluffy',
  'formidable',
  'friendly',

  // G was pretty bad
  'generous',
  'glamorous',
  'great',

  'handsome',
  'happy',
  'hilarious',
  'hungry',
  'imaginative',
  'impressive',
  'incredible',
  'influential',
  'inquisitive',
  'intellectual',
  'intelligent',
  'international',

  'jolly',
  'joyous',

  'keen',
  'kind',
  'known',

  'lexical',
  'linguistic',
  'lively',
  'lovely',
  'lucky',

  'magic',
  'magnetic',
  'magnificent',
  'marvellous',
  'melodic',
  'musical',
  'mysterious',

  'natural',
  'nice',
  'noble',
  'notable',

  'official',
  'olympic',
  'optimistic',
  'original',
  'outstanding',

  'patient',
  'peaceful',
  'personal',
  'philosophical',
  'pleasant',

  'poised',
  'positive',
  'principal',
  'productive',
  'professional',
  'proud',

  'quaint',
  'quick',
  'quintessential',

  'reliable',
  'remarkable',
  'respectable',
  'revolutionary',
  'robust',
  'royal',

  'scientific',
  'sensible',
  'sharp',
  'significant',
  'smiling',
  'sophisticated',
  'sparkling',
  'spectacular',
  'spicy',
  'splendid',
  'sporting',
  'sunny',
  'super',
  'superb',

  'technological',
  'thoughtful',
  'tremendous',
  'tropical',

  'ultimate',
  'universal',
  'useful',

  'valuable',
  'victorious',
  'vital',
  'vivacious',

  'wise',
  'witty',
  'wonderful',

  'zestful',
  'zesty',
  'zippy',
]
