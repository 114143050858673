import { createContext, MutableRefObject, useContext, useRef } from 'react'

import useLayoutEffect from 'utils/hooks/useIsomorphicLayoutEffect'

const TOOLTIP_PORTAL_CLASS = 'gamma-tooltip-portal'

export const useCreateTooltipPortal = () => {
  const ref = useRef<HTMLElement | null>(null)

  useLayoutEffect(() => {
    const el = document.createElement('div')
    el.className = TOOLTIP_PORTAL_CLASS
    document.body.appendChild(el)
    ref.current = el

    return () => {
      if (document.body.contains(el)) {
        document.body.removeChild(el)
      }
      ref.current = null
    }
  }, [])

  return ref
}

const TooltipPortalRefContext = createContext<
  MutableRefObject<HTMLElement | null>
>(null as any)

export const TooltipPortalRefProvider = TooltipPortalRefContext.Provider

export const useTooltipPortalRef = () => {
  return useContext(TooltipPortalRefContext)
}
