/**
 * UI states that others might want to be aware of. Right now, it only
 * tracks modals that could be open on top of the DocEditor
 */

import { createSlice } from '@reduxjs/toolkit'

export interface ModalState {
  openModals:
    | {
        string: boolean
      }
    | {}
}

const initialState: ModalState = {
  openModals: {},
}

const ModalStateSlice = createSlice({
  name: 'ModalState',
  initialState,
  reducers: {
    reset: () => initialState,
    setModalOpen(state, action: { payload: { id: string; isOpen: boolean } }) {
      const { id, isOpen } = action.payload
      state.openModals[id] = isOpen
    },
  },
})

type ReduxState = {
  [ModalStateSlice.name]: ModalState
}

export const { reset, setModalOpen } = ModalStateSlice.actions

export const selectIsAnyModalOpen = (state: ReduxState) => {
  const result = Object.values(state.ModalState.openModals).includes(true)
  return result
}

export const reducer = ModalStateSlice.reducer
