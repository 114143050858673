import { usePopper, UsePopperProps } from '@chakra-ui/react'
import { useEffect } from 'react'

import { useIsHovering, useIsHoveringProps } from './useHover'

type UseLightPopoverProps = UsePopperProps & useIsHoveringProps

const DEFAULT_OPTIONS: Partial<UseLightPopoverProps> = {
  enterDelay: 100,
  leaveDelay: 50,
  gutter: 16,
  placement: 'top',
  flip: true,
}

export const useLightPopover = (props?: UseLightPopoverProps) => {
  const options = { ...DEFAULT_OPTIONS, ...props }
  const { update, referenceRef, popperRef, getPopperProps } = usePopper(options)

  const { enterDelay, leaveDelay } = options
  const { isHovering, onMouseOut, onMouseOver } = useIsHovering({
    enterDelay,
    leaveDelay,
  })

  useEffect(() => {
    if (isHovering && update) {
      update()
    }
  }, [isHovering, update])

  return {
    referenceRef,
    popperRef,
    isHovering,
    onMouseOver,
    onMouseOut,
    getPopperProps,
  }
}

export const LightPopoverMotionProps = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { delay: 0.1, duration: 0.2 },
  },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
}
