import { FieldFunctionOptions, Reference } from '@apollo/client'
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common'

const isUndefinedOrNull = (val: any) => val === undefined || val === null

/**
 * Merge with the incoming one using Apollo's mergeObjects as long as it is truthy
 * (not undefined or null).
 */
export const mergeIfValue = (
  existing: Reference,
  incoming: Reference,
  {
    mergeObjects,
  }: {
    mergeObjects: FieldFunctionOptions['mergeObjects']
  }
) => {
  if (isUndefinedOrNull(incoming)) return existing
  const result = mergeObjects(existing, incoming)
  return result
}

/**
 * Replace the existing value with the incoming one as long as it is truthy
 * (not undefined or null).
 */
export const replaceIfValue = (existing: Reference, incoming: Reference) => {
  if (!isUndefinedOrNull(incoming)) {
    // New valid value
    return incoming
  }
  if (existing === undefined) {
    // We dont have any value yet, such as initial load
    return incoming
  }
  return existing
}

/**
 * Replace the existing value with the incoming one as long as it is defined.
 * (CAN be null but NOT undefined)
 */
export const replaceIfDefined = (existing: Reference, incoming: Reference) => {
  if (incoming !== undefined) {
    // New valid value
    return incoming
  }
  if (existing === undefined) {
    // We dont have any value yet, such as initial load
    return incoming
  }
  return existing
}

export const mergeArrayWithDelete =
  (
    includeArchived = false, // includeArchived false means `archived` will NOT be considered `deleted`
    getIdFn = (readField: ReadFieldFunction, data: Reference): string =>
      readField('id', data) as string
  ) =>
  (
    existing = [],
    incoming = [],
    {
      readField,
      mergeObjects,
      fieldName,
    }: {
      readField: FieldFunctionOptions['readField']
      mergeObjects: FieldFunctionOptions['mergeObjects']
      fieldName: FieldFunctionOptions['fieldName']
    }
  ) => {
    const dataIdToIndex: Record<string, number> = Object.create(null)
    if (!incoming) return existing
    if (existing) {
      existing.forEach((data, index) => {
        dataIdToIndex[getIdFn(readField, data)] = index
      })
    }
    const result = incoming
      .reduce(
        (acc: Array<Reference | null>, data: Reference) => {
          const id = getIdFn(readField, data)
          const index = dataIdToIndex[id]
          const deleted = readField<boolean>('deleted', data)
          const archived =
            includeArchived && readField<boolean>('archived', data)
          const isArchivedOrDeleted = deleted || archived
          if (typeof index === 'number') {
            if (isArchivedOrDeleted) {
              // (1) We found it, and it needs to be removed.
              acc[index] = null
            } else {
              // (2) We found it, and it needs to be updated.
              acc[index] = mergeObjects(acc[index] as Reference, data)
            }
          } else if (!isArchivedOrDeleted) {
            // (3) We didn't find it, and it needs to be added.
            dataIdToIndex[id] = acc.length
            acc.push(data)
          } else {
            // (4) We didn't find it, and it needs to be removed
            // No-op; the incoming deleted item wasn't found in the existing array
          }
          return acc
        },
        existing ? [...existing] : []
      )
      .filter(Boolean)

    return result
  }
