import { useEffect, useState } from 'react'

// Monitors a given stateful variable for changes
// and delays returning the new value to the caller
export function useStateDelayed<T>(value: T, delay: number): T {
  const [delayedVal, setVal] = useState<T>(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVal(value)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, delay])

  return delayedVal
}
