import { FlexProps, Flex } from '@chakra-ui/react'
import React from 'react'

export const Panel = React.forwardRef<HTMLDivElement, FlexProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Flex ref={ref} width="100%" {...rest}>
        {children}
      </Flex>
    )
  }
)
Panel.displayName = 'Panel'
