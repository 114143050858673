/**
 * Easing functions from https://easings.net/
 */
export function easeOutLinear(x: number): number {
  return x
}

export function easeOutSine(x: number): number {
  return Math.sin((x * Math.PI) / 2)
}

export function easeOutCubic(x: number): number {
  return 1 - Math.pow(1 - x, 3)
}

export function easeOutCirc(x: number): number {
  return Math.sqrt(1 - Math.pow(x - 1, 2))
}

export function easeOutQuad(x: number): number {
  return 1 - (1 - x) * (1 - x)
}
export function easeOutQuart(x: number): number {
  return 1 - Math.pow(1 - x, 4)
}

export function easeOutExpo(x: number): number {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
}

// https://github.com/mattdesl/lerp/blob/master/index.js
export function lerp(v0: number, v1: number, t: number) {
  return v0 * (1 - t) + v1 * t
}
