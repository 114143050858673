export const isValidUrl = (url: string) => {
  // todo: real validator
  try {
    new URL(url)
  } catch {
    return false
  }
  return true
}

export const startsWithHttp = (url: string) => {
  return url.toLowerCase().startsWith('http')
}

export const getLinkDomain = (url: string | undefined | null): string => {
  if (!url) {
    return ''
  }
  try {
    return new URL(url).hostname
  } catch (err) {
    return ''
  }
}

export const isImageUrl = (url: string) => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null
}
